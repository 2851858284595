import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactService} from '../services/ContactService/contact.service';
import {ToastrService} from 'ngx-toastr';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Contact } from '../models/Contact';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactForm: UntypedFormGroup;
    buttonPrompt = 'submit';
    // Phone NUmber Properties
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    maxLengthNumber = '15';
    preferredCountries: CountryISO[] = [CountryISO.Tunisia, CountryISO.Canada, CountryISO.Brazil];
    constructor(private contactService : ContactService, private toastService: ToastrService) {
    }

    ngOnInit(): void {
        this.contactForm = new UntypedFormGroup(
            {
              firstName: new UntypedFormControl('', [
                    Validators.required,
                    Validators.pattern(/^[\p{L}'-]+$/u),
                ]),
              lastName: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(/^[\p{L}'-]+$/u),
              ]),
                companyName: new UntypedFormControl('', [
                Validators.required,
              ]),
              webSiteLink: new UntypedFormControl('', []),
              phone: new UntypedFormControl('', [
                Validators.required,
              ]),
              email: new UntypedFormControl('', [
                Validators.required,
                Validators.email,
              ]),
              subject: new UntypedFormControl('', [
                Validators.required,
              ]),
              message: new UntypedFormControl('', [
                Validators.required,
              ]),
            }
        );
    }
    get firstName(){
        return this.contactForm.get('firstName');
    }
    get lastName(){
        return this.contactForm.get('lastName');
    }
    get companyName(){
        return this.contactForm.get('companyName');
    }
    get webSiteLink(){
        return this.contactForm.get('webSiteLink');
    }
    get phone(){
        return this.contactForm.get('phone');
    }
    get email(){
        return this.contactForm.get('email');
    }
    get subject(){
        return this.contactForm.get('subject');
    }
    get message(){
        return this.contactForm.get('message');
    }
    sendMail(){
        const contact: Contact = this.contactForm.value;
        contact.phone = this.contactForm.value.phone.internationalNumber;
        this.contactService.sendEmail(contact).subscribe(
            {
                next: (data: any) => {
                    this.toastService.success('Your email has been sent successfully', 'Success')
                },
                complete: () => {
                    this.buttonPrompt = 'Submit'
                    this.contactForm.reset();
                }, error: (err) => {
                    this.toastService.error('An error occurred while sending your email; Please try again', 'Mail Not Sent')
                }
            })
    }
    btnClick(){
        this.buttonPrompt = 'Sending';
    }

}
