import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import Stepper from 'bs-stepper';
import {ToastrService} from 'ngx-toastr';
import {RentalRequest} from '../models/rentalRequest';
import {RentalRequestService} from '../services/RentalRequest/rental-request.service';
import {environment} from '../../../environments/environment';
import {ReCaptcha2Component} from 'ngx-captcha';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import { Country, State, City }  from 'country-state-city';

@Component({
  selector: 'app-joinus',
  templateUrl: 'join-us.component.html',
  styleUrls: ['./join-us.component.css'],
})
export class JoinUsComponent implements OnInit, OnDestroy {
  registerform: UntypedFormGroup;
  private stepper: Stepper;
  showValidationError = false;
  companyStep = false;
  additionalStep = false;
  // Captcha Properties
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  public captchaIsLoaded = false;
  public captchaReset = false;
  public captchaSucess = false;
  public captchaIsExpired = false;
  public captchaResponse? : string;
  public captchaTheme: 'light' | 'dark' = 'light';
  public captchaSize: 'compact' | 'normal' = 'normal';
  public captchaLang =  'en';
  public captchaType : 'image' | 'audio';
  recaptchaSecret: string = environment.captchaKey;
  // Phone NUmber Properties
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  maxLengthNumber = '15';
  preferredCountries: CountryISO[] = [CountryISO.Tunisia, CountryISO.Canada, CountryISO.Brazil];
  // Country and Cities Properties
  countryList;
  citiesList;
  public emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  constructor(private fb: UntypedFormBuilder, private rentalService: RentalRequestService, private toastr: ToastrService) {
    const formcontrols = {
      firstName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s'éçàè]+$/),
      ]),
      lastName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s'éçàè]+$/),
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(this.emailPattern)
      ]),
      secondEmail: new UntypedFormControl('', [
        // Validators.email,
        // Validators.pattern(this.emailPattern)
      ]),
      phone: new UntypedFormControl('', [
        Validators.required,
      ]),
      secondPhone: new UntypedFormControl('', [
      ]),
      matriculateFiscal: new UntypedFormControl(''),
      company: new UntypedFormControl('', [
        Validators.required,
      ]),
      address: new UntypedFormControl('', [
        Validators.required,
      ]),
      country: new UntypedFormControl('', [
        Validators.required,
      ]),
      city: new UntypedFormControl('', [
        // Validators.required,
      ]),
      postalCode: new UntypedFormControl('', [
        // Validators.optional,
        Validators.pattern(/^[0-9]+$/),
      ]),
      webSiteLink: new UntypedFormControl('', []),
      linkedinUrl: new UntypedFormControl('', []),
      recaptcha: new UntypedFormControl('', [Validators.required])
    };
    this.registerform = this.fb.group(formcontrols);
    this.countryList = Country.getAllCountries();
  }

  get firstName(){
    return this.registerform.get('firstName');
  }

  get lastName(){
    return this.registerform.get('lastName');
  }

  get email(){
    return this.registerform.get('email');
  }

  get secondEmail(){
    return this.registerform.get('secondEmail');
  }

  get phone(){
    return this.registerform.get('phone');
  }

  get secondPhone(){
    return this.registerform.get('secondPhone');
  }

  get address(){
    return this.registerform.get('address');
  }

  get matriculateFiscal(){
    return this.registerform.get('matriculateFiscal');
  }
  get company(){
    return this.registerform.get('company');
  }

  get country(){
    return this.registerform.get('country');
  }

  get city(){
    return this.registerform.get('city');
  }

  get postalCode(){
    return this.registerform.get('postalCode');
  }


  get linkedinUrl(){
    return this.registerform.get('linkedinUrl');
  }

  get webSiteLink(){
    return this.registerform.get('webSiteLink');
  }
  get recaptcha(){
    return this.registerform.get('recaptcha');
  }

  stepOwnerIsInvalid() : boolean{
    return this.firstName.invalid || this.lastName.invalid || this.email.invalid || this.phone.invalid;
  }
  stepCompanyIsInvalid() : boolean {
    return this.matriculateFiscal.invalid || this.company.invalid || this.country.invalid || this.city.invalid || this.postalCode.invalid
  }

  next(step : string)
  {
    if (step === 'company'){
      if (this.stepOwnerIsInvalid()) {
        this.showValidationError = true;
      }else {
        this.companyStep = true;
        this.showValidationError = false;
        this.stepper.next();
      }
    } else if (step === 'additional'){
      if (this.stepCompanyIsInvalid()) {
        this.showValidationError = true;
      }else {
        this.additionalStep = true;
        this.showValidationError = false;
        this.stepper.next();
      }
    }
  }

  previous(){
    this.stepper.previous();
  }

  ngOnInit() {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
  }

  register(): void {
     const  request:RentalRequest=this.registerform.value;
     request.phone = this.registerform.value.phone.internationalNumber
     request.secondPhone = this.registerform.value.secondPhone?.internationalNumber ?
         this.registerform.value.secondPhone?.internationalNumber : ''
     request.country = this.registerform.value.country?.name
    console.log(request)
    this.rentalService.sendRequest(request).subscribe(res => {
      if((res.status=== 'APPROVED')||(res.status=== 'BLOCKED') ){
        this.toastr.info('this request is ' + res.status , 'Information');
      }else if(res.status === 'PENDING'){
        this.toastr.success('Your account activation request sent successfully!', 'Success');
        console.log(res);
      }
      },err => {
      if (err.status === 302) {
        this.toastr.warning('An account with this Tax Number or Email already exists', 'Account Activation Request Failed');
      } else {
        this.toastr.error('An error occurred while sending your request. Try again later ', 'Account Activation Request Failed');
      }
      });
  }

  handleCaptchaSuccess(result){
    this.captchaSucess = !!result;
    this.captchaResponse = this.captchaSucess ? result : null;
  }
  handleCaptchaReset(){
    this.captchaReset = true;
  }
  handleCaptchaExpire(){
    this.captchaIsExpired = true;
  }
  handleCaptchaLoad(){
    this.captchaIsLoaded = true;
  }
  onClick(){
    if(this.registerform.valid){
      this.register();
    }
  }
  getCountryStates(){
    this.citiesList = State.getStatesOfCountry(this.country.value.isoCode);
  }
  ngOnDestroy() {}

}
