import { Component, OnInit } from '@angular/core';
import { Contact } from '../models/Contact';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../services/ContactService/contact.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  contactList:  Contact[] = [];
  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
  ) { }

  ngOnInit(): void {
    this.getContact();
  }

  getContact(): void {
    this.contactList = []; 
    this.contactService.getAllContacts().subscribe(contacts => {
        contacts.forEach(contact => {
            this.contactList.push(contact); 
           
        });
    });
}

}
