import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../../models/AuthUser';
import { Users } from '../../models/Users';
import { Loginrequest } from './Loginrequest';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  constructor(private http: HttpClient) { }

  authUrl = environment.auth;


  register(auth : AuthUser): Observable<AuthUser> {
    return this.http.post<AuthUser>(environment.apiRegister+'api/auth/signup' ,auth, this.httpOptions);
  }

  validateToken(token : string): Observable<boolean> {
    return this.http.get<boolean>(environment.apiRegister+'api/auth/validateToken/'+token , this.httpOptions);
  }

  refreshToken(email: string) {
    return this.http.get(environment.apiRegister+'api/auth/generateToken/'+email , this.httpOptions);
  }
  verifyCode(codeVerification: string): Observable<any> {
    return this.http.post(environment.apiRequest + 'administration/rentalRequest/verifyCode', { codeVerification }, this.httpOptions);
  }
  regenerateVerificationCode(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.http.post('https://gateway.manajero.com/administration/rentalRequest/regenerateCode', null, { params , responseType: 'text' });
  }


  login(loginRequest: Loginrequest): Observable<RegisterService> {
    return this.http.post<AuthUser>(`${this.authUrl}signin`, loginRequest)
      .pipe(
        tap(response => {
          const userId = response.id; // Récupérez l'ID de l'utilisateur depuis la réponse
          console.log("this userId"+ userId)
          localStorage.setItem('userId', userId); // Stockez l'ID de l'utilisateur dans le localStorage
        })
      );
  }

 


  getCurrentUser(): Observable<Users> {
    const userId = localStorage.getItem('userId');
    if (userId) {
      return of({ id: userId } as Users);
    } else {
      return of(null);
    }
  }

}

function tap(arg0: (response: any) => void): import("rxjs").OperatorFunction<AuthUser, RegisterService> {
  throw new Error('Function not implemented.');
}
