import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RentalRequest} from '../../models/rentalRequest';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { Contact } from '../../models/Contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {
  }

  url = environment.apiRequest + 'administration/api/contact';
  apiRequest: 'https://gateway.manajero.com/'


  sendEmail(body:any) {
    return this.http.post(environment.apiRequest+'administration/rentalRequest/contact', body, this.httpOptions);
  }



  

  getAllContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.url+"/contacts");
  }




  getContactById(id: string): Observable<Contact> {
    const url = `${this.url}/${id}`;
    return this.http.get<Contact>(url);
  }

  createContact(contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(this.url, contact);
  }

  updateContact(id: string, contact: Contact): Observable<Contact> {
    const url = `${this.url}/${id}`;
    return this.http.put<Contact>(url, contact);
  }

  deleteContact(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }



  getContactByUserId(userId: string): Observable<Contact> {
    return this.http.get<Contact>(`${this.url}/getContactByUserId/${userId}`);
  }

 /* updateContactLogo(id: string, formData: FormData): Observable<Contact> {
    const updateUrl = `${this.url}/${id}/logo`;
    return this.http.put<Contact>(updateUrl, formData);
  }*/

  updateLogo(contactId: string, formData: FormData): Observable<Contact> {
    return this.http.put<Contact>(`${this.url}/${contactId}/logo`, formData);
  }
}
