import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../services/ContactService/contact.service';
import { Contact } from '../models/Contact';
import { FAQService } from '../services/faq/faq.service';
import { TermsAndConditionsService } from '../services/terms-and-conditions.service';
import { PrivacyPolicyService } from '../services/privacy-policy/privacy-policy.service';
import { DocumentationService } from '../services/documentation/documentation.service';
import { FAQ } from '../models/FAQ';
import { PrivacyPolicy } from '../models/PrivacyPolicy';
import { TermsAndConditions } from '../models/TermsAndConditionst';
import { Documentation } from '../models/Documentation';
import { SocialLinksService } from '../services/socialLinks/social-links.service';
import { SocialLinks } from '../models/SocialLinks';
declare const L: any;
import { SocialLinksStatus } from '../models/SocialLinksStatus';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  title = 'location';

  displayStyleFAQModal = 'none';
  displayStylePrivacyPolicyModal = 'none';
  displayStyleTermsModal = 'none';
  displayStylePricingModal = 'none';
  displayStyleSupportModal = 'none';
  faq: FAQ;
  privacypolicy: PrivacyPolicy;
  termsandcondtions: TermsAndConditions;
  documentation: Documentation;
  socialLinksList: SocialLinks[]=[];

  contactList:  Contact[] = [];



  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    private faqService: FAQService,
   private termsAndConditionsService: TermsAndConditionsService,
    private privacyPolicyService: PrivacyPolicyService,
    private documentationService: DocumentationService,
    private socialLinksService: SocialLinksService,




  ) { }




  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.getContact();
    this.getFAQ();
    this.getDocumentation();
    this.getPrivacyPolicy();
    this.getTermsAndCondtions();
    this.getSocialLinks();
  }
  

  getContact(): void {
    this.contactList = []; 
    this.contactService.getAllContacts().subscribe(contacts => {
        contacts.forEach(contact => {
            this.contactList.push(contact); 
            if (contact.adresseLatitude && contact.adresseLongitude) {
                const coords: [number, number] = [parseFloat(contact.adresseLatitude), parseFloat(contact.adresseLongitude)];
                this.initializeMap(coords);
            }
        });
    });
}

  
  getSocialLinks(): void {


    this.socialLinksList = []; 
    this.socialLinksService.getAllSocialLinks().subscribe(socialLinks => {
      socialLinks.forEach(sl => {
            this.socialLinksList.push(sl); 
          });
        });
    }
    
  
  
  




  getFAQ(): void {
   // const id = this.route.snapshot.paramMap.get('id');
   const id = "6421c4f392bd4319ba8120e4";

    if (id) {
      this.faqService.getFAQByUserId(id)
        .subscribe(faq => {
          this.faq = faq;
          console.log("id is : "+id)
        });
    } else {
      console.log("No faq ID provided.");
    }
  }
  getTermsAndCondtions(): void {
  //  const id = this.route.snapshot.paramMap.get('id');
  const id = "6421c4f392bd4319ba8120e4";

    if (id) {
      this.termsAndConditionsService.getTermsAndConditionsByUserId(id)
        .subscribe(termsandcondtions => {
          this.termsandcondtions = termsandcondtions;
          console.log("id is : "+id)
        });
    } else {
      console.log("No terms and conditions ID provided.");
    }
  }

  getPrivacyPolicy(): void {
    const id = "6421c4f392bd4319ba8120e4";

   // const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.privacyPolicyService.getPrivacyPolicyByUserId(id)
        .subscribe(privacypolicy => {
          this.privacypolicy = privacypolicy;
          console.log("id is : "+id)
        });
    } else {
      console.log("No privac ypolicy ID provided.");
    }
  }
  getDocumentation(): void {
    //const id = this.route.snapshot.paramMap.get('id');
    const id = "6421c4f392bd4319ba8120e4";

    if (id) {
      this.documentationService.getDocumentationByUserId(id)
        .subscribe(documentation => {
          this.documentation = documentation;
          console.log("id is : "+id)
        });
    } else {
      console.log("No documentation  ID provided.");
    }
  }
  initializeMap(coords: [number, number]): void {
    const mymap = L.map('map').setView(coords, 13);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      lang: 'en'
    }).addTo(mymap);

    const marker = L.marker(coords).addTo(mymap);
  }

  openTermsPopup() {
    this.displayStyleTermsModal = 'block';
  }

  closeTermsPopup() {
    this.displayStyleTermsModal = 'none';
  }

  openFAQPopup() {

    this.displayStyleFAQModal = 'block';
    console.log('this.displayStyleFAQModal'+ this.displayStyleFAQModal)
  }

  openPrivacyPolicyPopup() {
    this.displayStylePrivacyPolicyModal = 'block';
  }
  openPricingPopup() {
    this.displayStylePricingModal = 'block';
  }

  openSupportPopup() {
    this.displayStyleSupportModal = 'block';
  }



  closeFAQPopup() {
    this.displayStyleFAQModal = 'none';
  }

  closePrivacyPolicyPopup() {
    this.displayStylePrivacyPolicyModal = 'none';
  }



  closePricingPopup() {
    this.displayStylePricingModal = 'none';
  }

  closeSupportPopup() {
    this.displayStyleSupportModal = 'none';
  }
}