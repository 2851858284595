import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TermsAndConditions } from '../models/TermsAndConditionst';


@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {

  baseUrl = environment.apiRequest + 'administration/api/termsandconditions';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  getAllTermsAndConditions(): Observable<TermsAndConditions[]> {
    return this.http.get<TermsAndConditions[]>(`${this.baseUrl}`);
  }

  getTermsAndConditionsById(id: string): Observable<TermsAndConditions> {
    return this.http.get<TermsAndConditions>(`${this.baseUrl}/${id}`);
  }

  createTermsAndConditions(termsAndConditions: TermsAndConditions): Observable<TermsAndConditions> {
    return this.http.post<TermsAndConditions>(`${this.baseUrl}`, termsAndConditions);
  }

  updateTermsAndConditions(id: string, termsAndConditions: TermsAndConditions): Observable<TermsAndConditions> {
    return this.http.put<TermsAndConditions>(`${this.baseUrl}/${id}`, termsAndConditions);
  }

  deleteTermsAndConditions(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

  getTermsAndConditionsByUserId(userId: string): Observable<TermsAndConditions> {
    return this.http.get<TermsAndConditions>(`${this.baseUrl}/getTermsAndConditionsByUserId/${userId}`);
  }
}
