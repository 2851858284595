import { Component, OnInit } from '@angular/core';
import { Country } from 'country-state-city';
import { TranslationService } from '../services/TranslationService/translation.service';
import { Contact } from '../models/Contact';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../services/ContactService/contact.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  contact: Contact;
  countries = [];

  constructor(
    public translationService: TranslationService,
    public route: ActivatedRoute,
    public contactService: ContactService
  ) { }

  ngOnInit(): void {
    this.countries.push(Country.getCountryByCode('GB'));
    this.countries.push(Country.getCountryByCode('FR'));
    this.countries.push(Country.getCountryByCode('ES'));
    this.countries.push(Country.getCountryByCode('PT'));
    this.countries.push(Country.getCountryByCode('SA'));
    this.getContact();
  }

  switchLang(lang: string) {
    this.translationService.switchLang(lang);
  }

  getFlagByCountry(lang: string): string {
    const countryCode = this.getCountryCodeByLang(lang);
    const country = this.countries.find(c => c.isoCode === countryCode);
    return country ? `https://flagcdn.com/w20/${countryCode.toLowerCase()}.png` : '';
  }

  getCountryCodeByLang(lang: string): string {
    switch (lang) {
      case 'en': return 'GB';
      case 'fr': return 'FR';
      case 'es': return 'ES';
      case 'pt': return 'PT';
      case 'ar': return 'SA';
      default: return '';
    }
  }

  getLanguageName(lang: string): string {
    switch (lang) {
      case 'en': return 'English';
      case 'fr': return 'French';
      case 'es': return 'Spanish';
      case 'pt': return 'Portuguese';
      case 'ar': return 'Arabic';
      default: return '';
    }
  }

  getContact(): void {
    const id = '612d7667e6df9f001f64735e';
    this.contactService.getContactById(id).subscribe(contact => {
      if (contact) {
        this.contact = contact;
        console.log('Contact:', contact);
      } else {
        console.log('Contact not found');
      }
    });
  }

  getImageUrlFromBinaryData(binaryData: string): string {
    if (!binaryData) {
      console.log('Binary data is empty');
      return '';
    }
    return 'data:image/jpeg;base64,' + binaryData;
  }

  scrollToJoinForm() {
    const element = document.getElementById('join-form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
