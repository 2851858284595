import { Component, OnInit } from '@angular/core';
import { Contact } from '../models/Contact';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../services/ContactService/contact.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  contactList:  Contact[] = [];
  constructor(
    public route: ActivatedRoute,
    public contactService: ContactService) {

    
  }
  ngOnInit(): void {
    this.getContact();
  }

 

getContact(): void {
  this.contactList = []; 
  this.contactService.getAllContacts().subscribe(contacts => {
      contacts.forEach(contact => {
          this.contactList.push(contact); 
         
      });
  });
}
}