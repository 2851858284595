import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import Stepper from 'bs-stepper';
import {AuthUser} from '../models/AuthUser';
import {RegisterService} from '../services/RegisterService/register.service';
import {ToastrService} from 'ngx-toastr';
import {ReCaptcha2Component} from 'ngx-captcha';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  private stepper: Stepper;

  displayStyle = 'none';
  displayStyleEmailModal = 'none';
  displayStyleTermsModal = 'none';
  opacity = '0.8';
  token = '';
  codeVerification= '';
  _email = '';
  roles;
  matriculate = '';
  isCodeVerified = false;
  errorMessage = '';
  isValid = true;
  authUser: AuthUser = new AuthUser();
  formRegister: UntypedFormGroup;
  showPassword = false;
  showcfPassword = false;
  showValidationErrors = false;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  public captchaIsLoaded = false;
  public captchaReset = false;
  public captchaSucess = false;
  public captchaIsExpired = false;
  public captchaResponse? : string;
  public captchaTheme: 'light' | 'dark' = 'light';
  public captchaSize: 'compact' | 'normal' = 'normal';
  public captchaLang =  'en';
  public captchaType : 'image' | 'audio';
  recaptchaSecret: string = environment.captchaKey;
  @ViewChild('input0') input0: ElementRef;
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private router: Router,
      private route: ActivatedRoute,
      private us: RegisterService,
      private toastr: ToastrService
  ) {}

  get email() {
    return this.formRegister.get('email');
  }
  get password() {
    return this.formRegister.get('password');
  }
  get cfPassword() {
    return this.formRegister.get('cfPassword');
  }
  get answer1() {
    return this.formRegister.get('answer1');
  }
  get answer2() {
    return this.formRegister.get('answer2');
  }
  get answer3() {
    return this.formRegister.get('answer3');
  }
  get acceptTerms() {
    return this.formRegister.get('acceptTerms');
  }
  get recaptcha(){
    return this.formRegister.get('recaptcha');
  }
  openPopup() {
    this.displayStyle = 'block';
  }
  openEmailPopup(){
    this.displayStyleEmailModal = 'block';
  }
  openTermsPopup(){
    this.displayStyleTermsModal = 'block';
  }
  closeTermsPopup(){
    this.displayStyleTermsModal = 'none';
  }
  stepPasswordIsInvalid() : boolean{
    return this.password.invalid;
  }
  stepcfPasswordIsInvalid() : boolean{
    return this.cfPassword.invalid;
  }
  stepSecurityIsInvalid() : boolean{
    return this.answer1.invalid || this.answer2.invalid || this.answer3.invalid || this.acceptTerms.invalid || this.recaptcha.invalid
  }
  ngOnInit(): void {
    this.route.queryParamMap
    .subscribe((params) => {
      this.token = params.get('token');
      this._email = params.get('email');
      this.matriculate = params.get('mat');
      this.roles = params.get('role');
    }
  );
    this.callToken(this.token);

    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });

    this.formRegister = this.formBuilder.group(
        {
          email: new UntypedFormControl(this?._email, [Validators.required, Validators.email]),
          password: new UntypedFormControl('', [
              Validators.required,
              Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/)]),
          cfPassword: new UntypedFormControl('', [Validators.required]),
          answer1: new UntypedFormControl('', [Validators.required]),
          answer2: new UntypedFormControl('', [Validators.required]),
          answer3: new UntypedFormControl('', [Validators.required]),
          recaptcha: new UntypedFormControl('', [Validators.required]),
          acceptTerms: [false, Validators.requiredTrue]
        },
        {
      validator: this.ConfirmedValidator('password', 'cfPassword'),
        }
    )
  }

  callToken(token: string) {
    if(token == null){
      this.openEmailPopup();
      this.isValid = false
    }
    this.us.validateToken(token).subscribe(
        (res) => {
          if (!res) {
            console.log(res)
            this.openPopup();
            this.isValid = false;
          }
        },
        (err) => {
          // this.openPopup();
          // this.isValid = false;
        }
    );
  }

  callRegister() {
    this.authUser=  new AuthUser();
    const data = this.formRegister.value;
    this.authUser.password = data.password;
    this.authUser.email = data.email;
    this.authUser.answer1 = data.answer1;
    this.authUser.answer2 = data.answer2;
    this.authUser.answer3 = data.answer3;
    this.authUser.roles = this.roles=== null
        ? [{
          id : null,
          name : 'ROLE_ADMIN'
        }]
        : [this.roles];
    this.authUser.matriculate = this.matriculate;

    this.us.register(this.authUser).subscribe(
      (res) => {
        this.toastr.success('Your account has been created successfully!', 'Success');
        // link to admin web app
        window.location.href = 'http://admin.manajero.com/#/home';
      },
      (err) => {
        if (err.status === 302) {
          this.toastr.warning('An account with this email address already exists', 'Account Registration Failed');
        } else {
          this.toastr.error('An error occurred while adding your account. Try again later ', 'Account Registration Failed');
        }
      }
    );
  }

  callEmailRefresh() {

    this.us.refreshToken(this._email).subscribe(
        (res: any) => {
          console.log(res.token)
          window.location.href = 'https://manajero.com/#/register?email='+this._email+'&token='+res.token+'&mat='+this.matriculate;
          window.location.reload()
        },
        (err) => {
          this.toastr.error('Unable to regenerate Session', 'Service Unavailable');
        }
    );
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
          matchingControl.errors &&
          !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  handleCaptchaSuccess(result){
    this.captchaSucess = !!result;
    this.captchaResponse = this.captchaSucess ? result : null;
  }
  handleCaptchaReset(){
    this.captchaReset = true;
  }
  handleCaptchaExpire(){
    this.captchaIsExpired = true;
  }
  handleCaptchaLoad(){
    this.captchaIsLoaded = true;
  }
  onClick(){
    if(this.formRegister.valid){
      this.callRegister();
    }
  }
  goToRental() {
    this.router.navigate(['/joinus']);
  }
  toggleShowPassword(){
    this.showPassword = !this.showPassword;
  }
  toggleShowCfPassword(){
    this.showcfPassword = !this.showcfPassword;
  }



  onKeyup(event: any, index: number) {
    const currentElement: HTMLInputElement = event.target;
    const previousElement: HTMLInputElement = currentElement.previousElementSibling as HTMLInputElement;

    // Si ce n'est pas le premier input et que le champ précédent est vide, replacer le focus sur le champ précédent
    if (index > 0 && (!previousElement || !previousElement.value)) {
      previousElement.focus();
      return; // Sortir de la fonction pour ne pas traiter d'autres logiques
    }

    const validKeys = /^[a-zA-Z0-9]$/; // Regex pour les caractères valides (lettres et chiffres)

    // Vérifier si la touche pressée est un caractère valide
    if (validKeys.test(event.key)) {
      // Construire le code de validation complet
      this.codeVerification = '';
      for (let i = 0; i <= 5; i++) {
        this.codeVerification += (document.getElementById(`input${i}`) as HTMLInputElement).value;
      }

      if (this.codeVerification.length === 6) {
        this.verifyCode(); // Appeler la fonction de vérification lorsque le code a 6 chiffres
      } else {
        // Se focaliser sur le prochain champ s'il est valide et disponible
        const nextElement: HTMLInputElement = currentElement.nextElementSibling as HTMLInputElement;
        if (nextElement) {
          nextElement.readOnly = false; // Rendre le prochain champ modifiable
          nextElement.focus(); // Mettre le focus sur le prochain input
        }
      }

      // Rendre le champ actuel readonly après la saisie
      currentElement.readOnly = true;
    }
  }




  verifyCode() {
    this.us.verifyCode(this.codeVerification).subscribe({
      next: (response) => {
        console.log('Code verified successfully', response);
        this.isCodeVerified = true;
        this.stepper.next();
      },
      error: (error) => {
        console.error('Verification failed', error);
        this.isCodeVerified = false;
        if (error.error && error.error.errorCode) {
          this.errorMessage = this.getErrorMessage(error.error.errorCode); // Utiliser errorCode pour déterminer le message
          if (error.error.errorCode === 'EXPIRED_CODE') {
            this.toastr.warning(this.errorMessage, 'EXPIRED CODE');
          } else {
            // Utiliser toastr.error pour les autres types d'erreurs
            this.toastr.error(this.errorMessage, 'INVALID CODE');
          }
        } else {
          this.toastr.error('Erreur inattendue. Veuillez réessayer plus tard.'); // Message d'erreur par défaut
        }
        this.resetInputs();
      }
    });
  }


  getErrorMessage(errorCode: string): string {
    switch (errorCode) {
      case 'INVALID_CODE':
        return 'the verification code is invalid. Please check and try again.';
      case 'EXPIRED_CODE':
        return 'the verification code has expired. Please request a new one.';
      default:
        return 'Unexpected error. Please try again later.';
    }
  }

  resetInputs() {
    for (let i = 0; i <= 5; i++) {
      const input = (document.getElementById(`input${i}`) as HTMLInputElement);
      input.value = ''; // Effacer la valeur
      input.readOnly = i !== 0; // Seul le premier champ est modifiable
    }
    this.input0.nativeElement.focus(); // Mettre le focus sur le premier champ de saisie
  }
  regenerateCode() {
    if (this._email) {
      this.us.regenerateVerificationCode(this._email).subscribe({
        next: (response) => {
          console.log('Nouveau code généré avec succès', response);
          this.toastr.success('New verification code generated successfully. Please check your email.', 'Success');
        },
        error: (error) => {
          console.error('Erreur lors de la génération du nouveau code', error);
        }
      });
    }
  }
}