import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {PrivacyPolicy} from '../../models/PrivacyPolicy';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrivacyPolicyService {

  private baseUrl = environment.apiRequest + 'administration/api/privacypolicies';


  constructor(private http: HttpClient) { }

  getAllPrivacyPolicies(): Observable<PrivacyPolicy[]> {
    return this.http.get<PrivacyPolicy[]>(this.baseUrl);
  }

  getPrivacyPolicyById(id: string): Observable<PrivacyPolicy> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<PrivacyPolicy>(url);
  }

  createPrivacyPolicy(privacyPolicy: PrivacyPolicy): Observable<PrivacyPolicy> {
    return this.http.post<PrivacyPolicy>(this.baseUrl, privacyPolicy);
  }

  updatePrivacyPolicy(id: string, privacyPolicy: PrivacyPolicy): Observable<PrivacyPolicy> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.put<PrivacyPolicy>(url, privacyPolicy);
  }

  deletePrivacyPolicy(id: string): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  getPrivacyPolicyByUserId(userId: string): Observable<PrivacyPolicy> {
    const url = `${this.baseUrl}/user/${userId}`;
    return this.http.get<PrivacyPolicy>(url);
  }
}
