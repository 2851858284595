import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.addLangs(['en', 'fr' , 'ar', 'es', 'pt']);
    translate.use('en');
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
  getLangs(){
    return this.translate.getLangs();
  }
  getCurrentLang(){
    return this.translate.currentLang;
  }
}
