import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {Abonnement} from '../../models/Abonnement';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService {

  // URL de base pour l'API d'abonnement
  private apiUrl = environment.apiRequest + 'administration/api/abonnement';

  // Options HTTP, incluant les headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  // Injection de HttpClient pour faire les requêtes HTTP
  constructor(private http: HttpClient) {}

  // Récupérer tous les abonnements
  getAbonnements(): Observable<Abonnement[]> {
    return this.http.get<Abonnement[]>(`${this.apiUrl}/abonnements`, this.httpOptions);
  }

  // Récupérer un abonnement par ID
  getAbonnementById(id: string): Observable<Abonnement> {
    return this.http.get<Abonnement>(`${this.apiUrl}/${id}`, this.httpOptions);
  }

  // Créer un nouvel abonnement
  createAbonnement(abonnement: Abonnement): Observable<Abonnement> {
    return this.http.post<Abonnement>(this.apiUrl, abonnement, this.httpOptions);
  }

  // Mettre à jour un abonnement existant
  updateAbonnement(id: string, abonnement: Abonnement): Observable<Abonnement> {
    return this.http.put<Abonnement>(`${this.apiUrl}/${id}`, abonnement, this.httpOptions);
  }

  // Supprimer un abonnement
  deleteAbonnement(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`, this.httpOptions);
  }
}