import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {RentalRequest} from '../../models/rentalRequest';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RentalRequestService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
  }

  sendRequest(user: RentalRequest): Observable<RentalRequest> {
    return this.http.post<RentalRequest>(environment.apiRequest + 'administration/rentalRequest/sendRequest', {user}, this.httpOptions);
  }

  getRentalRequestByEmail(email: string): Observable<RentalRequest> {
    console.log('Fetching rental request by email:', email); // Log l'email envoyé
    return this.http.get<RentalRequest>(environment.apiRequest + `administration/rentalRequest/find-by-email?email=${email}`,
        this.httpOptions);
  }
}
