import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Documentation } from '../../models/Documentation';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {

  private baseUrl = environment.apiRequest + 'administration/api/documentations';

  constructor(private http: HttpClient) { }

  getAllDocumentation(): Observable<Documentation[]> {
    return this.http.get<Documentation[]>(this.baseUrl);
  }

  getDocumentationById(id: string): Observable<Documentation> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Documentation>(url);
  }

  createDocumentation(documentation: Documentation): Observable<Documentation> {
    return this.http.post<Documentation>(this.baseUrl, documentation);
  }

  updateDocumentation(id: string, documentation: Documentation): Observable<Documentation> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.put<Documentation>(url, documentation);
  }

  deleteDocumentation(id: string): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  getDocumentationByUserId(userId: string): Observable<Documentation> {
    const url = `${this.baseUrl}/user/${userId}`;
    return this.http.get<Documentation>(url);
  }
}
