import {Role} from './Role';


export class AuthUser{

    email : string ;
    password : string;
    answer1 : string;
    answer2 : string;
    answer3 : string;
    roles : Role[];
    matriculate: string;
}
