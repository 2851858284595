// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiRequest: 'https://gateway.manajero.com/',
  apiRegister:'https://gateway.manajero.com/',
  apiGateway: 'http://194.146.13.225:30573/',
  captchaKey : '6LfmQuApAAAAAATqCAGdXDM_SemlGDv80qRBsNUX',
  auth: 'https://gateway.manajero.com/api/auth/',


  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';// Included with Angular CLI.
